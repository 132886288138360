import React from 'react';
import Page from '../components/Page';

function NotFound() {
  const body = (
    <p style={{ textAlign: 'center', fontSize: '1.25rem' }}>
      Whoops! Looks like someone is in the wrong place.
    </p>
  );

  return <Page title={'Not Found'} body={body} />;
}

export default NotFound;
